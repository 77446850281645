<template>
  <div class="bloco">
    <Banner :bannerInfo="bannerInfo" />
    <!-- <div class="news-destaque">
      <NewsDestaque @showDestaque="showDestaque" />
    </div> -->
  </div>
</template>

<script>
import Banner from "@/components/Banner";
// import NewsDestaque from "@/components/News/NewsDestaque";

export default {
  name: "bloco",
  components: {
    Banner,
    // NewsDestaque,
  },
  props: ["showDestaque"],
  data() {
    return {
      bannerInfo: {},
    };
  },
  methods: {
    async getBanner() {
      const res = await this.$axios.get("banner").catch((e) => {
        console.error("banner", e);
      });
      if (res.status === 200) {
        this.bannerInfo = res.data;
      }
    },
  },
  created() {
    this.getBanner();
  },
};
</script>

<style lang="scss">
.bloco {
  height: 90%;
  width: 100%;
  display: flex;
  flex-direction: column;
  background-color: var(--background-color-light);
  padding-top: 20px;

  .banner {
    margin: 15px 30px 30px 30px;
  }
}
</style>
